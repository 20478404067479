<script setup lang="ts">
import BaseButton from '@/components/Buttons/BaseButton.vue'
import IconBase from '@/components/Icons/IconBase.vue'
import BorderText from '@/components/BorderText.vue'
import { useAuthStore } from '@/stores/auth'
import { computed, onMounted, ref } from 'vue'
import axios from 'axios'
import { API_URL } from '@/helpers/globalVars'
import { useRoute } from 'vue-router'

const route = useRoute()
const authStore = useAuthStore()
const isAuth = computed(() => authStore.isAuth)
const playListId = computed(() => route.params.id as string)
const votesList = ref([])
const isVotes = ref(false)

const getVotesList = async () => {
  await axios.get(`${API_URL}options/${playListId.value}`)
    .then((response) => {
      votesList.value = response.data
    }).catch((e) => {
      console.log('Votes error', e)
    })
}
const setVotes = async (optionId: string) => {
  if (isVotes.value) {
    return undefined
  }
  isVotes.value = optionId
  await axios.put(`${API_URL}options/${playListId.value}/${optionId}`)
    .catch((error) => {
      console.log('Vote error', error)
    })
}
onMounted(() => {
  getVotesList()
})
</script>

<template>
  <div class="votes px-20 py-10">
    <h3 class="mb-20">Проголосуй за лучшую идею</h3>
    <h6 class="mb-20">Голосуй и отслеживай популярность в твоём личном кабинете</h6>
    <BaseButton @click="isAuth && setVotes(vote.option_id)" v-for="vote in votesList?.options" v-bind:key="vote.option_id" class="large rounded full-width vote-btn mb-20" bg="none" :border="isVotes === vote.option_id ? 'selected' : 'gradient'">
      <p class="text">{{ vote.option_name }}</p>
      <icon-base v-if="!isVotes" :name="isAuth ? 'close' : 'lock'" view-box="0 0 16 18" :width="16" :height="18"/>
      <span v-else>{{ vote.percentage }}%</span>
    </BaseButton>
<!--    <div class="d-flex justify-center text-center flex-direction-column mt-40">-->
<!--      <h6 class="text-center">Результаты 1го тура голосования</h6>-->
<!--      <h6 class="text-gradient d-inline-flex margin-auto">20 декабря 2024 года</h6>-->
<!--    </div>-->
    <BorderText bg="bg-gray">
      <h6 class="text-center">О сроках выхода серии сообщим дополнительно</h6>
    </BorderText>
  </div>
</template>

<style scoped lang="less">
.votes {
  .vote {
    &-btn {
      .text {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      span {
        margin-right: 10px;
      }
    }
  }
}
</style>
