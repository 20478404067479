<script setup lang="ts">

import BaseInput from '@/components/Inputs/BaseInput.vue'
import BaseButton from '@/components/Buttons/BaseButton.vue'
import BackLine from '@/components/Lines/BackLine.vue'
import { ref } from 'vue'
import CircleButton from '@/components/Buttons/CircleButton.vue'
import router from '@/router'
import { useAuthStore } from '@/stores/auth'
import { restore } from '@/helpers/auth'
import { IValidateForm, Validate } from '@/helpers/validate'

const step = ref(1)
const login = ref<string>('')
const code = ref('')
const password = ref('')
const errors = ref<any>({})
const isLoading = ref<boolean>(false)
const currentField = ref<string>('')

const authStore = useAuthStore()
const setStep = (stepValue: number): void => {
  step.value = stepValue
}
const setLoading = (status: boolean) => {
  isLoading.value = status
}
const inFocus = (input: string) => {
  currentField.value = input
  errors.value = {}
}
const outFocus = (
  input: string,
  // ESLINT-ERROR: Старая реализация
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  model: any,
  title: string,
  rules: string[]
) => {
  currentField.value = ''
  errors.value = Validate([
    {
      name: input,
      rules: rules as IValidateForm['rules'],
      value: model,
      ru: title
    }
  ])
}
const onSubmit = async () => {
  setLoading(true)
  const validate = Validate([
    {
      name: 'login',
      rules: ['required'],
      value: login.value,
      ru: 'Ник / Почту'
    }
  ])
  console.log(validate)
  errors.value = {}
  if (Object.keys(validate).length) {
    errors.value = validate
    setLoading(false)
  } else {
    const result = await restore(login.value).finally(() => setLoading(false))
    if (result.isSend) {
      await router.push('/login/restore-success')
    }
  }
}
</script>

<template>
  <BackLine>
    <CircleButton v-if="step === 1" @click="router.go(-1)" name="chevron-left-gradient" bg="empty" :iconWidth="21" :iconHeight="16" view-box="0 0 21 16"/>
    <CircleButton v-if="step > 1" @click="setStep(step - 1)" name="chevron-left-gradient" bg="empty" :iconWidth="21" :iconHeight="16" view-box="0 0 21 16"/>
  </BackLine>
  <div class="restore-account px-10 mb-20">
    <div v-if="step === 1" class="restore-account__wrapper text-center pa-40">
      <h2 class="mb-20">Восстановление пароля</h2>
      <form @submit.prevent="onSubmit" class="restore-account__form" enctype="multipart/form-data">
        <BaseInput
          @focusin="inFocus('login')"
          @focusout="outFocus('login', login, 'Ник / Почту', ['required'])"
          v-model="login"
          type="text"
          class="mb-10 full-width"
          placeholder="Ник / Почта"
        />
        <BaseButton :disabled="isLoading || !!Object.keys(errors).length" class="large rounded margin-auto mb-20 full-width" bg="gradient" border="gradient">Далее</BaseButton>
      </form>
      <div v-if="errors.login" class="text-gradient d-inline-flex flex-direction-column">
        <h6 class="text-center mb-10">{{errors.login[0]}}</h6>
      </div>
    </div>
    <div v-if="step === 2" class="restore-account__wrapper text-center pa-40">
      <h2 class="mb-10">Мы отправили код<br> на почту</h2>
      <h5 class="text-gradient text-center mb-20 d-inline-flex">{{login}}</h5>
      <div class="restore-account__form">
        <BaseInput v-model="code" class="mb-10 full-width" type="text" placeholder="Код"/>
        <BaseButton @click="setStep(3)" class="large rounded margin-auto mb-20 full-width" bg="gradient" border="gradient">Далее</BaseButton>
      </div>
      <div class="text-gradient d-inline-flex flex-direction-column">
        <h6 class="text-center mb-10"><span>Повторно отправить через</span> 30 секунд</h6>
        <br>
        <h5><span>Хотите создать новый аккаунт?</span></h5>
        <h5><router-link :to="{name: 'login-register'}">Зарегистрироваться</router-link></h5>
      </div>
    </div>
    <div v-if="step === 3" class="restore-account__wrapper text-center pa-40">
      <h2 class="mb-10">Новый пароль</h2>
      <h5 class="text-gradient text-center mb-20 d-inline-flex">{{login}}</h5>
      <div class="restore-account__form">
        <BaseInput v-model="password" class="mb-10 full-width" type="password" placeholder="Пароль"/>
        <router-link :to="{name: 'login-restore-success'}"><BaseButton class="large rounded margin-auto mb-20 full-width" bg="gradient" border="gradient">подтвердить</BaseButton></router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.restore-account {
  &__form {
    display: flex;
    flex-direction: column;
  }
  &__wrapper {
    position: relative;
    border: none;
    .text-gradient {
      h6, h5 {
        background:var(--gradient-1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        span {
          background: none;
          -webkit-text-fill-color: #fff;
        }
      }
    }
  }
}
</style>
