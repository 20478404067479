<script setup lang="ts">
import { defineProps } from 'vue'
const props = defineProps({
  height: Number
})
const getRandomInt = (minIn, maxIn) => {
  const min = Math.ceil(minIn)
  const max = Math.floor(maxIn)
  return Math.floor(Math.random() * (max - min + 1) + min)
}
</script>

<template>
<div class="wrapper">
  <div :key="`rand_${item}`" v-for="item in getRandomInt(5,10)" :style="{'height': `${height}px`, 'width': `${getRandomInt(80,150)}px`}"></div>
</div>
</template>

<style scoped lang="less">
.wrapper {
  display: flex;
  flex-wrap: wrap;
  div {
    margin: 5px;
    animation: pulse-bg 1s infinite;
  }
}
@keyframes pulse-bg {
  0% {
    background-color: var(--loader-pulse-color);
  }
  50% {
    background-color: var(--loader-pulser-secondary-color);
  }
  100% {
    background-color: var(--loader-pulse-color);
  }
}
</style>
