<script setup lang="ts">
import { onMounted, ref, defineProps, computed } from 'vue'
import CircleButton from '@/components/Buttons/CircleButton.vue'
import { getMediaById } from '@/api'
import Hls from 'hls.js'

import backstageImg from '@/assets/backstage.png'
import { useAuthStore } from '@/stores/auth'
const props = defineProps<{
  trailerId: string | undefined,
  backstPict: string | null
}>()

const authStore = useAuthStore()
const isAuth = computed(() => authStore.isAuth)

const backstage = props.backstPict ? props.backstPict : backstageImg
const hls = ref()
const containerBackStageVideo = ref<HTMLVideoElement | null>(null)
const isPlaying = ref(false)
const trailer = ref()
const isOverLayHidden = ref(false)
const overlayTimer = ref()
const isShowPreviewImage = ref(true)
const volume = ref(1)
const loadBackStageDataAsync = async () => {
  try {
    const { data } = await getMediaById(props.trailerId)
    trailer.value = data
    if (Hls.isSupported()) {
      hls.value = new Hls()
    }
    hls.value.loadSource(trailer.value.hls_info.master_file)
    hls.value.attachMedia(document.getElementById('containerBackStageVideo'))
    hls.value.on(Hls.Events.MEDIA_ATTACHED, function () {
      containerBackStageVideo.value.volume = volume.value
      containerBackStageVideo.value.addEventListener('play', (e) => {
        isShowPreviewImage.value = false
        isPlaying.value = true
        setTimeout(() => {
          isOverLayHidden.value = true
        }, 1000)
      })
      containerBackStageVideo.value.addEventListener('pause', () => {
        isPlaying.value = false
        if (overlayTimer.value) {
          clearTimeout(overlayTimer.value)
        }
        isOverLayHidden.value = false
      })
    })
  } catch (e) {
    console.log('Get media error', e)
  }
}
const overlayHandler = () => {
  isOverLayHidden.value = false
  const time = setTimeout(() => {
    if (isPlaying.value) {
      clearTimeout(time)
      isOverLayHidden.value = true
    }
  }, 3000)
}
const toggleVolume = () => {
  volume.value = Number(!volume.value)
  containerBackStageVideo.value.volume = volume.value
}
if (props.trailerId) {
  loadBackStageDataAsync()
}
</script>

<template>
  <div v-if="trailerId && isAuth" @click="overlayHandler" class="player-backstage">
    <div v-if="!isOverLayHidden" :class="!isPlaying ? 'player-backstage__overlay player-backstage__overlay-black':'player-backstage__overlay'">
      <CircleButton @click="containerBackStageVideo.play()" v-if="!isPlaying" class="btn-player__action play" bg="none" name="play" :iconWidth="47" :iconHeight="47" view-box="0 0 28.58 39.49"/>
      <CircleButton @click="containerBackStageVideo.pause()" v-else class="btn-player__action play" bg="none" name="pause" :iconWidth="47" :iconHeight="47" view-box="0 0 28.58 39.49"/>
      <div class="controls mb-20">
        <div class="control-right">
          <CircleButton @click="toggleVolume" :name="volume ? 'volume':'mute'" :iconWidth="16" :iconHeight="18" view-box="0 0 16 18"/>
        </div>
      </div>
    </div>
    <div v-if="trailer && isShowPreviewImage" class="preview-image">
      <img :src="trailer?.poster_url" alt="">
    </div>
    <video playsInline ref="containerBackStageVideo" id="containerBackStageVideo" class="player-backstage__background"/>
  </div>
  <div v-else class="backstage_lock-player p-relative">
    <img class="backstage_lock-player__background" :src="backstage" :alt="backstage" />
    <div v-if="!isAuth" class="backstage_lock-player__overlay">
      <CircleButton class="btn-player__action play" bg="none" name="lock" :iconWidth="47" :iconHeight="47" view-box="0 0 28.58 39.49"/>
    </div>
    <h5 v-if="!props.backstPict" class="p-absolute bottom-40 full-width text-center">Бекстейджи готовятся! Ожидайте в скором времени</h5>
  </div>
</template>

<style scoped lang="less">
.backstage_lock-player {
  position: relative;
  &__background {
    width: 100%;
    border-radius: 20px;
  }
  &__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    .bg-none {
      color: #ffffff;
      border: rgba(217, 217, 217, 0.2) solid 2px;
      background: rgba(217, 217, 217, 0.2)
    }
  }
}
.player-backstage {
  position: relative;
  &__background {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
  &__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    &-black {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 20px;
    }
    .bg-none {
      opacity: 1;
      color: #ffffff;
      border: #ffffff solid 2px;
    }
    .controls {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      .control-right {
        position: absolute;
        right: 15px;
        bottom: 0;
      }
    }
  }
  .preview-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
  }
}

@media (min-width:491px){
  .player-backstage, .backstage_lock-player {
    width: 450px;
    margin: 0 auto;
  }
}
</style>
