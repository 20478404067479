<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import CircleButton from '@/components/Buttons/CircleButton.vue'

const items = [1]
</script>

<template>
  <swiper
    @init="onSwiper"
    :loop="true"
    :slidesPerView="1"
    :space-between="20"
    class="slider"
  >
    <swiper-slide :data-hash="`slide${index + 1}`" :key="`media_${index}`" v-for="(index) in items" class="series">
      <div class="p-relative full-width">
        <div class="player-preview series__background">
          <div class="player-preview__background"></div>
          <div class="player-preview__overlay">
            <CircleButton class="btn-player__action play" bg="none" name="play" :iconWidth="47" :iconHeight="47" view-box="0 0 28.58 39.49"/>
          </div>
        </div>
        <div class="series__wrapper">
          <div class="ribbons"></div>
          <div class="series__info">
            <h2 class="series__info-title"></h2>
            <h6 class="series__info-category uppercase"></h6>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<style scoped lang="less">
.slider {
  padding: 20px 10px;
  width: 100%;
  .series {
    width: auto;
    display: flex;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
    a {
      position: relative;
    }
    &__background {
      object-fit: cover;
      width: 100%;
    }
    &__wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 15px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.13%, rgba(0, 0, 0, 0.7) 100%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__info {
      &-title {
        width: 150px;
        height: 20px;
        animation: pulse-bg 1s infinite;
      }
      &-category {
        width: 100px;
        height: 10px;
        animation: pulse-bg 1s infinite;
      }
    }
  }
}
.player-preview {
  position: relative;
  &__background {
    border-radius: 20px;
    height: 550px;
    width: 100%;
    animation: pulse-bg 1s infinite;
  }
  &__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &-black {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 20px;
    }
    .bg-none {
      opacity: 1;
      color: #ffffff;
      border: #ffffff solid 2px;
    }
  }
}
@keyframes pulse-bg {
  0% {
    background-color: var(--loader-pulse-color);
  }
  50% {
    background-color: var(--loader-pulser-secondary-color);
  }
  100% {
    background-color: var(--loader-pulse-color);
  }
}
</style>
