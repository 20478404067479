import { Http, clearToken, getRefreshToken } from '@/helpers'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useUserStore } from '@/stores/user'

export const useAuthStore = defineStore('auth-store', () => {
  const userStore = useUserStore()

  const token = ref<string | null>(localStorage.getItem('token'))
  const username = ref<string | null>(localStorage.getItem('username'))
  const promoPopupVisibled = ref<string | null>(localStorage.getItem('promoPopupVisibled'))

  const isAuth = computed(() => !!token.value)

  const isShowPromoPopup = computed(() => !promoPopupVisibled.value)

  const setToken = async (result: { token: string; username: string }) => {
    Http.defaults.headers.Authorization = result.token
    localStorage.setItem('token', result.token)
    localStorage.setItem('username', result.username)
    token.value = result.token
    username.value = result.username
    await userStore.getUserAsync()
  }

  const removeToken = async () => {
    clearToken()
    token.value = null
    username.value = null
    await userStore.setTheme('b')
  }

  const setHidePromoPopup = async () => {
    localStorage.setItem('promoPopupVisibled', 'true')
    promoPopupVisibled.value = 'true'
  }

  const AUTH = '/login'
  const REGISTER = '/signup/'

  const login = async (form: { username: string; password: string }) => {
    const result: {
      result: object
      error: { errorCode: string; errorMessage: string }
    } = {
      result: {},
      error: {
        errorCode: '',
        errorMessage: ''
      }
    }
    try {
      const response = await Http.post(AUTH, form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      result.result = response.data
      // ESLINT-ERROR: Старая реализация
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.response) {
        result.error = e.response.data.non_field_errors
        return result
      }
      result.error = e
    }
    return result
  }
  const register = async (form: { username: string; password1: string; name: string; email: string }) => {
    const result: {
      result: object
      errors: []
    } = {
      result: {},
      errors: []
    }
    try {
      const response = await Http.post(REGISTER, form)
      result.result = response.data
      // ESLINT-ERROR: Старая реализация
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.response) {
        result.errors = e.response.data?.detail?.errors || ['Ошибка регистрации.']
        return result
      }
      result.errors = e
    }
    return result
  }
  // ESLINT-ERROR: Старая реализация
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setAuth = (payload: any) => {
    if (Object.keys(payload.result).length) {
      setToken(payload.result)
    } else {
      removeToken()
    }
  }
  const logout = () => {
    removeToken()
    window.location.href = '/'
  }

  return {
    token,
    isAuth,
    username,
    isShowPromoPopup,
    setToken,
    removeToken,
    login,
    register,
    setAuth,
    logout,
    setHidePromoPopup
  }
})
