import { createApp } from 'vue'
import router from './router'
import Vue3TouchEvents from 'vue3-touch-events'
import App from './App.vue'
import './registerServiceWorker'

import '@/assets/styles/main.less'
import { createPinia } from 'pinia'

const pinia = createPinia()

createApp(App).use(router).use(Vue3TouchEvents).use(pinia).mount('#app')
