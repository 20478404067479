<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'

import 'swiper/css'
const items = [1, 2, 3, 4, 5]

</script>
<template>
  <swiper
    :slides-per-view="'auto'"
    :space-between="20"
    class="slider"
    :loop="true"
    :centered-slides="true"
    :centeredSlidesBounds="true"
  >
    <swiper-slide :key="`slide_${index}`" v-for="(index) in items" class="slide">
      <div class="full-width">
        <div class="slide__background" />
        <div class="slide__wrapper">
          <div class="ribbons"></div>
          <div class="slide__info">
            <h2 class="slide__info-title"></h2>
            <h5 class="slide__info-description"></h5>
            <h6 class="slide__info-category uppercase"></h6>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<style scoped lang="less">
.slider {
  padding: 20px 10px;
  .slide {
    width: 40%;
    display: inline-flex;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
    &__background {
      object-fit: cover;
      width: 100%;
      height: 500px;
      background-color: #dddddd;
      animation: pulse-bg 1s infinite;
    }
    &__wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 15px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.13%, rgba(0, 0, 0, 0.7) 100%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__info {
      &-title {
        width: 150px;
        height: 20px;
        animation: pulse-bg 1s infinite;
      }
      &-description {
        width: 185px;
        height: 15px;
        animation: pulse-bg 1s infinite;
      }
      &-category {
        width: 100px;
        height: 10px;
        animation: pulse-bg 1s infinite;
      }
    }
  }
}
@media (min-width:767px) and (max-width:1024px) {
  .slider {
    .slide {
      width: 50%;
    }
  }
}
@media (max-width:767px){
  .slider {
    .slide {
      width: 75%;
    }
  }
}
@media all and (orientation:landscape) and (min-height: 968px) {
  .slider {
    .slide {
      width: 50%;
    }
  }
}
@keyframes pulse-bg {
  0% {
    background-color: var(--loader-pulse-color);
  }
  50% {
    background-color: var(--loader-pulser-secondary-color);
  }
  100% {
    background-color: var(--loader-pulse-color);
  }
}
</style>
